/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"

import { Container, Row, Col, Image } from "react-bootstrap"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "../../../components/Header"
import Footer from "../../../components/Footer"

import { useGlobalState } from "../../../global/global"
import { getLanguageFile } from "../../../global/langauge.helper"
import { TextFormater } from "../../../global/textFormater"

import HYMOTS_IMG from "../../../../assets/images/hymots_license.png"
import HYMOTS_C from "../../../../assets/images/hymots-c-1.png"
import HYMOTS_G from "../../../../assets/images/hymots-g-1.png"
import HYMOTS_D from "../../../../assets/images/hymots-d-1.png"
import HYMOTS_3D from "../../../../assets/images/hymots-3D.png"
import HYMOTS_SCENE from "../../../../assets/images/scene2.png"

import "./styles.scss"

const HymotsPage = () => {
  const [state] = useGlobalState()

  return (
    <Container fluid className="main-container">
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>hymots®</title>
        </Helmet>
      </div>
      <Header />
      <Container className="introduction-container ">
        <Row>
          <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
            <div style={{ display: "flex", flexDirection: "row" }}>
              <h3 className="introduction-text-heading">hymots</h3>
              <h6 className="copyright-text">®</h6>
            </div>
            <h5 className="introduction-text">
              {getLanguageFile(state.language).Hymots.hymotsMainDescription}
            </h5>
          </Col>
          <Col className="introduction-img-col col-lg-6 col-12 order-1 order-lg-12">
            <Image src={HYMOTS_IMG} className="introduction_img" />
          </Col>
        </Row>
      </Container>
      <Container className="features-container">
        {/* <p className="features-text" style={{ marginTop: "80px" }}>
          {getLanguageFile(state.language).Hymots.hymotsDescriptionPartOne}
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Hymots.hymotsDescriptionPartTwo}
          <p className="features-text">
            {getLanguageFile(state.language).Hymots.hymotsDescriptionPartThree}
          </p>
        </p>
        <p className="features-text">
          {getLanguageFile(state.language).Hymots.hymotsDescriptionPartFour}
        </p>
        <p className="features-text">{getLanguageFile(state.language).Hymots.hymotsDescriptionPartFive}</p> */}

        <p className="features-text">
          <h4>
            <b>
              <center>
                {TextFormater(
                  "hymots<0>®</0>: Hybrid Multidomain Operations Tactics Strategy Simulator "
                )}{" "}
              </center>
            </b>
          </h4>
          <br />
          <p>
            {TextFormater(
              "Hybrid multidomain operations tactics strategy  simulator (hymots<0>®</0>) is a micro agent-based constructive modeling and simulations platform to support military experimentations, exercises and wargames. It has four main modules:"
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is for developing medium term (5-10 years) geostrategic foresights. It makes predictions on how a given geostrategic setting with state and non state actors may evolve. Various forms of conflict scenarios, including hybrid environments, grey zone conflicts and trade wars can be defined. That uses a game theoretic approach and can be described as an extensive form Bayesian geostrategic game with imperfect information."
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>d is for supporting the defense planning process with a simulation based experimentation tool. It finds out the optimum set of military capability requirements to counter a given set of opposing capabilities over a scenario. It is a constructive static and deterministic simulation tool."
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>c is for supporting multidomain (land, maritime, air, space, cyberspace, social) wargames and exercises in strategic, operational and tactical levels. It can simulate grey zone conflicts in hybrid environments, kinetic warfare and complex human made or natural disasters. It is a stochastic, continuous, interactive and constructive simulation tool that can run in multiple theaters over the World with tens of thousands of units and entities, high resolution topographic data and 300/1 simulation speeds. It also has an embedded highly scalable three dimensional visualization tool. "
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>e is for supporting military experimentations. It is hymots<0>®</0>c in the core. In addition to the core functionalities inherited from hymots<0>®</0>c, it has artificial intelligence modules and generates confidence intervals instead of giving just a result. hymots<0>®</0>e is designed to test concepts, doctrines and operational plans. "
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>has embedded seamless exercise management modules (etecube<0>®</0>) to analyze the exercise, experimentation and training objectives, to assess the performance of the audience in meeting their objectives, to design, to execute and to adapt main incident lists according to the objectives and the performance of the audience. "
            )}
          </p>
          <p>
            {TextFormater(
              "A three dimensional visualization tool is integrated with hymots<0>®</0> to simulate surveillance cameras and the payloads of aerial vehicles. "
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0> is also integrated with the engine for recognition and fusion (nginr<0>®</0>), which fuses information retrieved from big data sources such as measurement, signature and open source intelligence, visualizes the fused information and has strong data analytics features. It detects the scenarios (i.e., crises or situation) as the conditions for them are met and generates warnings. It is symbiotic with hymots<0>®</0>. Therefore, it can be used also for early crises and incident warning by using a run ahead symbiotic simulation. "
            )}
          </p>
          <Row>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={HYMOTS_3D} className="features_img"></Image>
            </Col>
            <Col className="features-img-col col-lg-6 col-12 ">
              <Image src={HYMOTS_SCENE} className="features_img"></Image>
            </Col>
          </Row>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("hymots<0>®</0>c")}</b>
          </h5>
          <p>
            {TextFormater(
              "hymots<0>®</0>c is a constructive, continuous, interactive, stochastic and dynamic simulation system for multidomain (land, maritime, air, space, cyberspace, social) wargames and exercises in strategic, operational and tactical levels. It can simulate hybrid environments, kinetic warfare and complex human made or natural disasters."
            )}
          </p>
          <p>
            {TextFormater(
              "The hymots<0>®</0> architecture is designed following the Modelling and Simulation as a Service (MSaaS) and micro agent-based simulation approaches, and hence achieves high scalability, performance and fidelity. The computations are highly parallelized enabled by hardware accelerated (GPU) processing techniques. Each military unit and actor is a separate process (i.e., a program) called a microagent. MSaaS modules and microagents interact with each other over a virtual theater that imitates the features of static and dynamic environment including terrain and weather. "
            )}
          </p>
          <p>
            {TextFormater(
              "Terrain is represented as squares, which is associated with a terrain feature, altitude and an environment. Inclination, weather conditions, traffic congestion in the area, the existence of roads and hostile entities and obstacles affect the environment value associated with terrain squares. hymots<0>®</0> is designed and tested to reach 300/1 simulation speed in a game box of 4.000km×4.000km with 40.000 agents when the size of terrain squares is 1km×1km. The size of terrain squares can be selected much smaller for higher resolution simulation studies by database designers. "
            )}
          </p>
          <p>
            {TextFormater(
              "Weather is represented partially three dimensional and partially two dimensional. Wind strength and direction, visibility and icing are modelled as cubes. Light intensity and direction, swell height and direction, tide, drift strength and direction, temperature, space weather, magnetic anomalies, precipitation type and volume are modelled as squares. The size of the weather squares and cubes are selected by database designers as the multiples of the terrain squares.  "
            )}
          </p>
          <p>
            {TextFormater(
              "In hymots<0>®</0>, multiple sides can be represented. There is no limit in the number of sides which can be friendly, ally, hostile, neutral or unknown to each other. Each side may have multiple factions. Microagents belong to factions and made up of combat systems and supplies. They can also be given a number of entity sets, which are vehicles or weapon systems. "
            )}
          </p>
          <p>
            {TextFormater(
              "Simulation speed can be adjusted anytime during the game. Not only the simulation speed but also the resolution can be controlled. The time resolution is the length of the time period for each simulation step. In other words, in every simulation step, microagents calculate the conditions for the end of the time period given as the time resolution which can be split seconds. Orders can be given to and reports can be received from agents when simulation is running without a need to pause the game. "
            )}
          </p>
          <Row>
            <Col className="features-img-col">
              <Image src={HYMOTS_C} className="features_img_center"></Image>
            </Col>
          </Row>
          <p>
            {TextFormater(
              "Chemical biological radiological nuclear (CBRN) agents, their spread, contamination, fall out and wind down effects are simulated. Communications and links, including wide and local area, among the agents are also modelled and plays a key role in detection, recognition and identification of the other entities in theater and the dissemination of the recognized picture. The microagents can be given various tasks if they are equipped for the tasks. "
            )}
          </p>
          <p style={{ fontWeight: "bold" }}>
            The tasks that can be given to ground agents include but not limited
            to the following:
          </p>
          <p>
            <ul>
              <Row>
                <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                  <li>
                    Move (tactical, administrative, covert, overt, embarked or
                    disembarked)
                  </li>
                  <li>Direct and indirect fire</li>
                  <li>
                    Air defense (ballistic missile defense, high-medium-low
                    altitude air defense, integrated air defense)
                  </li>
                  <li>Attack</li>
                  <li>Defense</li>
                  <li>Withdraw</li>
                  <li>Obstacles</li>
                </Col>
                <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                  <li>Sense</li>
                  <li>Joint intelligence, surveillance and reconnaissance</li>
                  <li>CBRN reconnaissance and decontamination</li>
                  <li>Supply, maintenance, medical evacuation</li>
                  <li>Engineering</li>
                  <li>Ambush</li>
                  <li>Raid</li>
                  <li>Take, secure prisoners of war</li>
                </Col>
              </Row>
            </ul>
            <p style={{ fontWeight: "bold" }}>
              The tasks that can be given to air agents include but not limited
              to the following:
            </p>
            <ul>
              <Row>
                <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                  <li>Package and composite air operation organization</li>
                  <li>Reconnaissance</li>
                  <li>Maritime patrol</li>
                  <li>Combat air patrol</li>
                  <li>Intercept, scramble</li>
                  <li>Close air support</li>
                  <li>Close combat attack</li>
                  <li>Offensive air support</li>
                  <li>Interdict</li>
                  <li>Escort</li>
                </Col>
                <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                  <li>Suppression of enemy air defense</li>
                  <li>Air ground attack</li>
                  <li>
                    Air mobility including tactical air landing, air drop, air
                    assault, insert, extract
                  </li>
                  <li> Air refuel</li>
                  <li> Airborne early warning and control</li>
                  <li>Search and rescue</li>
                  <li> Electronic warfare</li>
                  <li>Supply and maintenance</li>
                  <li> Civilian air traffic</li>
                </Col>
              </Row>
            </ul>
            <p style={{ fontWeight: "bold" }}>
              The tasks that can be given to maritime agents include but not
              limited to the following:
            </p>
            <p>
              <ul>
                <Row>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Naval formations</li>
                    <li>Naval move</li>
                    <li>Maritime mobility</li>
                    <li>Amphibious assault</li>
                    <li>Surface to surface engagement</li>
                    <li>Naval artillery</li>
                    <li>Maritime patrol</li>
                  </Col>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Lay and sweep naval mines</li>
                    <li>Anti submarine warfare</li>
                    <li>Air defense</li>
                    <li>Search and rescue</li>
                    <li>Supply and maintenance</li>
                    <li>Civilian maritime traffic</li>
                  </Col>
                </Row>
              </ul>
            </p>
            <p style={{ fontWeight: "bold" }}>
              The space operation related tasks are categorized as the
              following:
            </p>
            <p>
              <ul>
                <Row>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Space control operations</li>
                    <li>Space force enhancement operations</li>
                  </Col>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Space force application operations</li>
                    <li>Space support operations</li>
                  </Col>
                </Row>
              </ul>
            </p>
            <p style={{ fontWeight: "bold" }}>
              The cyber space related tasks are categorized as the following:
            </p>
            <p>
              <ul>
                <Row>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Tactical theater communications and networking</li>
                    <li>Integrated wide area communications and networking</li>
                    <li>Electronic counter measures</li>
                  </Col>

                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Electronic support measures</li>
                    <li>Emission guided ammunitions</li>
                    <li>Denial of service attacks</li>
                  </Col>
                </Row>
              </ul>
            </p>
            <p style={{ fontWeight: "bold" }}>
              The non military models include:
            </p>
            <p>
              <ul>
                <Row>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Earthquakes</li>
                    <li>Floods</li>
                    <li>Tsunamis</li>
                    <li>Land slides and avalanches</li>
                    <li>Epidemics, pandemics</li>
                    <li>Fires</li>
                    <li>Estinguish fire</li>
                    <li>Societies</li>
                  </Col>
                  <Col className="introduction-text-col col-lg-6 order-12 order-lg-1">
                    <li>Social and human behavior</li>
                    <li>Immigrants and internally displaced people</li>
                    <li>Air accidents</li>
                    <li>Maritime accidents</li>
                    <li>Oil/gas rig accidents</li>
                    <li>Aircraft/ship/train/bus hijacking</li>
                    <li>Hostages</li>
                    <li>Air, sea, soil pollution</li>
                  </Col>
                </Row>
              </ul>
            </p>
            <p>
              {TextFormater(
                "etecube<0>®</0> is an integrated part of hymots<0>®</0>. Incidents in etecube<0>®</0> can be related to the agents and screens in hymots<0>®</0>, hence a real time visualization of incidents and an automatic feed back to the objective management module are enabled. "
              )}
            </p>
            <p>
              Users can switch from two dimensional to three dimensional screen
              view if they have an asset such as a surveillance camera available
              at the location represented in the simulation.
            </p>
          </p>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("hymots<0>®</0>e")}</b>
          </h5>
          <p>
            {TextFormater(
              "hymots<0>®</0>e is a constructive, continuous, dynamic and stochastic simulation tool. It allows to give commands to the microagents while the simulation is running. Nevertheless, it is designed to support the experimentations and therefore run in long time steps such as several hours or days. Typically, the analysts enter a command set that represent a concept, course of action or operational plan, and receive the results at the end of a time period. "
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>e is hymots<0>®</0>c in the core. The main differences between hymots<0>®</0>e and hymots<0>®</0>c are the following:"
            )}
            <ul>
              <li>
                {TextFormater(
                  "hymots<0>®</0>c gives a result which is realistic but not essentially typical. hymots<0>®</0>e runs multiple times for the same set of commands and generates a confidence interval."
                )}
              </li>
              <li>
                {TextFormater(
                  "hymots<0>®</0>c expects the users to change the commands when the model cannot comply with an order due to reasons such as the lack of supplies or combat systems. hymots<0>®</0>e tries to automatically correct and comply with orders."
                )}
              </li>
              <li>
                {TextFormater(
                  "hymots<0>®</0>e includes artificial intelligence and data analytics tools to support analysts."
                )}
              </li>
            </ul>
          </p>
          <p>
            {TextFormater(
              "etecube<0>®</0> supports the analysts in design of experiment and collect data automatically from hymots<0>®</0>e based on the design of experiment."
            )}
          </p>
          <p>
            {TextFormater(
              "The analysts can anytime use the embedded three dimensional visualization tool to gain further insight into a situation during a simulation and visually validate the results of the simulation."
            )}
          </p>
          <h5 style={{ color: "black" }}>
            <b>{TextFormater("hymots<0>®</0>g")}</b>
          </h5>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is an extensive form Bayesian geostrategic game with imperfect information."
            )}
          </p>
          <p>
            {TextFormater(
              "The main output of hymots<0>®</0>g is a world map on which all the state and not state actors are visualized. For the actors, values for 11 instruments (political, diplomacy, information, military, economic, financial, intelligence, infrastructure, social, law enforcement and technology), 41 instrument related parameters and various variables are visualized. The world map is colored according to the selected instrument or parameter values. In addition to that, as the conditions for the defined scenarios are met, warnings with various color codes appear in the screen. The users can query on those warnings. "
            )}
          </p>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is used for analyzing and assessing the consequences of the detected scenarios (i.e., incidents or crises) in short and medium term. When used together with nginr<0>®</0>, hymots<0>®</0>g also provides the analysts with an insight into the relations of an incident with the other incidents, actors and locations and also compare them with the learned patterns. Each incident and actor have an associated blockchain in nginr<0>®</0>. When the actors interact with each other or the other incidents, they share blocks with the blockchains dedicated to the interacted incidents and actors. These blockchains and their relations can be visualized on the nginr<0>®</0> map which has five dimensions (three geographical dimensions, time and interactions)."
            )}
          </p>
          <p>
            {TextFormater(
              "The analysts can let hymots<0>®</0>g to select the strategies for the actors in the geostrategic game, and they can interact with the game anytime before or during the simulation to change the selected strategies or to enter new strategies for a selected actor. Strategies have conditions to be fulfilled for being applicable. For example, a strategy may have an exclusive relations with a set of other strategies or may be required to be run together with a set of strategies. Strategies may also have constraints related to the instrument, instrument parameter or variable values. Once a strategy is applied, that may not be cancelled for a period of time and its effects are observed after a while which are parameters defined in the system database. The effects of the strategies on the variables are also defined in the system database."
            )}
          </p>
          <Row>
            <Col className="features-img-col">
              <Image src={HYMOTS_G} className="features_img_center"></Image>
            </Col>
          </Row>
          <p>
            {TextFormater(
              "hymots<0>®</0>g is for supporting strategic level processes including strategic foresight development, crises detection and warning, situation appreciation, response option development. It imitates a volatile, complex and ambiguous setting with limitless uncertainties for short and medium term (5-10 years) geostrategic foresight generation. A reasonable database preparation effort suffices to prepare it for complex simulation tasks. The system can reach up to 300/1 simulation speed within a global scenario. It can also be linked with nginr<0>®</0>. Hence, a digital twin of the real world can be created that can detect the crises patterns through symbiotic simulation and crisis or incident pattern recognition."
            )}
          </p>
          <p>
            {TextFormater(
              "For further information about the engine for recognition and fusion (nginr<0>®</0>), please visit "
            )}
            <Link to={"https://www.nginr.info"} target="_blank">
              www.nginr.info
            </Link>
          </p>
          <h5 style={{ color: "black" }}>
            {" "}
            <b>{TextFormater("hymots<0>®</0>d")}</b>
          </h5>
          <p>
            {TextFormater(
              "hymots<0>®</0>d is a static and deterministic computer simulation system to support defense planning process."
            )}
          </p>
          <p>
            {TextFormater(
              "It calculates the optimum set of military capability requirements to counter enemy capabilities over a scenario. The military capability requirements are selected from a predefined capability set and optimized according to the criteria as well as constraints given by the user. The optimization is achieved through the simplex method. hymots®d supports the analysts also with a sensitivity analysis module."
            )}
          </p>
          <Row>
            <Col className="features-img-col">
              <Image src={HYMOTS_D} className="features_img_center"></Image>
            </Col>
          </Row>
          <h5 style={{ color: "black" }}>
            {" "}
            <b>{TextFormater("etecube<0>®</0>")}</b>
          </h5>
          <p>
            {TextFormater(
              "etecube<0>®</0> is a toolset to plan and manage education training exercise experimentation and evaluation activities. It has the following modules:"
            )}
            <ul>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Objective Management Module (OMM):
                </h6>
                OMM is for analyzing the scenario and main incident list
                conditions, key processes and targeted key functional areas for
                each training and experimentation objective
              </li>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Experiment Management Module (EMM):
                </h6>
                EMM is for designing experiments according to the
                experimentation objectives and steering experiments towards
                their objectives.
              </li>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Defense Plan Management Module (DMM):
                </h6>
                DMM manages defense planning scenarios, and the capability
                requirement lists.
              </li>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Flow Management Module (FMM):
                </h6>
                Main incident list (MIL) developers use FMM to design a MIL and
                manage it dynamically according to the exercise and training
                objectives.
              </li>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Lessons Identified and Lessons Learned Module (LILL):
                </h6>
                LILL records observations and appropriations, and support the
                LILL analysts to verify, manage and validate lessons.
              </li>
              <li>
                <h6 style={{ color: "black", fontSize: 14 }}>
                  Real Life Support Module (RLSM):
                </h6>
                RLSM is for managing the configuration change requests and
                trouble tickets.
              </li>
            </ul>
            <p>
              {TextFormater(
                "For further information about etecube<0>®</0>, please visit "
              )}
              <Link to={"https://www.etecube.info"} target="_blank">
                www.etecube.info
              </Link>
            </p>
          </p>
        </p>
      </Container>
      <Footer />
    </Container>
  )
}

export default HymotsPage
